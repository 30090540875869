import React from 'react';
import { injectIntl } from 'react-intl';
import List from './list';
import PropTypes from 'prop-types';
import Dropdown from './../dropdown';
import { ButtonIcon } from './../buttons';
import style from './list.module.scss';

const getCategories = function(data, categoryName) {
    const categories = data.map(( item ) => {
        return item[categoryName];
    });
    const uniqueCategories = (list) => list.filter((v,i) => list.indexOf(v) === i);

    return uniqueCategories(categories);
};

class ListContainer extends React.PureComponent {

    state = {
        categoryMain: '',
        categorySecondary: '',
        filteredData: this.props.data  || [],
        page: 1,
        pageSize: this.props.pageSize || 10,
        totalPages: (Math.ceil(this.props.data.length / this.props.pageSize)) || 0
    };

    handleFilterChange = (event) => {
        const mainCategory = (event.target.getAttribute('id') === 'categoryMain' && event.target.value !== this.state.categoryMain) ? event.target.value : this.state.categoryMain;
        const secondaryCategory = (event.target.getAttribute('id') === 'categorySecondary' && event.target.value !== this.state.categorySecondary) ? event.target.value : this.state.categorySecondary;

        let filteredData = this.props.data;

        if (mainCategory !== '') {
            filteredData = filteredData.filter((item) => {
                if (item.categoryMain === mainCategory) {
                    return item;
                }
                return null;
            });
        }

        if (secondaryCategory !== '') {
            filteredData = filteredData.filter((item) => {
                if (item.categorySecondary === secondaryCategory) {
                    return item;
                }
                return null;
            });
        }

        const totalPages = Math.ceil(filteredData.length / this.state.pageSize);

        this.setState({
            [event.target.getAttribute('id')]: event.target.value,
            filteredData,
            totalPages,
            page: 1
        });
    };

    handleNavPrev = () => {
        const newPage = (this.state.page -1 > 1) ? this.state.page -1 : 1;
        this.setState({ page: newPage });
    };

    handleNavNext = () => {
        const newPage = (this.state.page + 1 < this.state.totalPages) ? this.state.page +1 : this.state.totalPages;
        this.setState({ page: newPage });
    };

    render() {
        const { data, intl, categoryMainLabel, categorySecondaryLabel, showImage, disableSecondaryFilter } = this.props;
        const prevNavDisabled = (this.state.page === 1);
        const nextNavDisabled = (this.state.page === this.state.totalPages);

        return (
            <div className={style.listContainer}>
                <div className={style.listHeader}>
                    <form>
                        <Dropdown id="categoryMain" className={style.listDropdown} onChange={this.handleFilterChange} value={this.state.filterValue}>
                            <option value="">{intl.formatMessage({id: 'select'})} {categoryMainLabel}</option>
                            {getCategories(data, 'categoryMain').map(( item, index ) => (<option key={`categoryMain${index}`} value={item}>{item}</option>))}
                        </Dropdown>
                        {(data.some(dataObject => dataObject.categorySecondary !== undefined) && !disableSecondaryFilter) &&
                        <Dropdown id="categorySecondary" className={style.listDropdown} onChange={this.handleFilterChange} value={this.state.filterValue}>
                            <option value="">{intl.formatMessage({id: 'select'})} {categorySecondaryLabel}</option>
                            {getCategories(data, 'categorySecondary').map(( item, index ) => (<option key={`categorySecondary${index}`} value={item}>{item}</option>))}
                        </Dropdown>
                        }
                    </form>
                    <span className={style.resultText}>{this.state.filteredData.length} {intl.formatMessage({id: 'results'}, {itemCount: this.state.filteredData.length })}</span>
                </div>
                <List data={this.state.filteredData} page={this.state.page} pageSize={this.state.pageSize} showImage={showImage} />
                <div className={style.listFooter}>
                    <ButtonIcon className={style.listFooterButton} icon="arrowLeft" disabled={prevNavDisabled} onClick={this.handleNavPrev}/>
                    <ButtonIcon className={style.listFooterButton} icon="arrowRight" disabled={nextNavDisabled} onClick={this.handleNavNext}/>
                </div>
            </div>
        );
    }
}

ListContainer.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        categoryMain: PropTypes.string,
        categorySecondary: PropTypes.string,
        image: PropTypes.object,
    })).isRequired,
    pageSize: PropTypes.number,
    categoryMainLabel: PropTypes.string,
    categorySecondaryLabel: PropTypes.string,
    showImage: PropTypes.bool,
    disableSecondaryFilter: PropTypes.bool,
};

export default injectIntl(ListContainer);
