import React from 'react';
import style from './dropdown.module.scss';
import { ReactComponent as ArrowDropdown } from './../../images/Icons/arrow-dropdown.svg';

const Dropdown = ({ className, ...props }) => (
    <div className={[style.customdropdown, className].join(' ')}>
        <ArrowDropdown className={style.dropdownIcon} />
        <select {...props}>
            {props.children}
        </select>
    </div>
);

export default Dropdown;
