import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import BlendImage from '../blendImage';
import { ReactComponent as ArrowRight } from './../../images/Icons/arrow-next.svg';
import style from './list.module.scss';

const ListItem = ({ title, categoryMain, categorySecondary, image, className, slug, showImage, ...rest }) => (
    <li className={[style.listItem, className].join(' ')} {...rest} >
        <Link to={slug}>
            <div className={style.listItemContent}>
                <h3 className={style.listItemTitle}>{title}</h3>
                <span className={style.listItemCategory}>{categoryMain}</span>
                {categorySecondary &&
                <span className={style.listItemCategory}> / {categorySecondary}</span>
                }
            </div>
            {(showImage && image) &&
            <BlendImage
                className={style.imagePlaceholder}
                fluid
                src={image}
                alt="list image"
            />
            }
            {!showImage &&
            <div className={style.listItemArrowContainer}>
                <ArrowRight />
            </div>
            }
        </Link>
    </li>
);

ListItem.propTypes = {
    title: PropTypes.string,
    categoryMain: PropTypes.string,
    categorySecondary: PropTypes.string,
    image: PropTypes.object,
    slug: PropTypes.string,
    showImage: PropTypes.bool,
};

export default ListItem;
