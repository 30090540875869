import React from 'react';
import { graphql } from 'gatsby';
import { injectIntl } from 'react-intl';
import Wrapper from './../components/wrapper';
import { LanguageLayout } from './../containers/layouts';
import WrapperContent from './../components/wrapperContent';
import PageIntro from './../components/pageIntro';
import { Title } from './../components/headings';
import List from './../components/list';

export const VacancyOverviewTemplate = ({ intl, title, titleOffer, titleSearch, description, vacancies, offerItemsPerPage, searchItemsPerPage }) => (
    <>
        <PageIntro color="thinair">
            <Wrapper>
                <WrapperContent>
                    <PageIntro.title>{title}</PageIntro.title>
                    <PageIntro.description html>{description}</PageIntro.description>
                </WrapperContent>
            </Wrapper>
        </PageIntro>
        <Wrapper>
            <WrapperContent>
                {(vacancies.gezocht && vacancies.gezocht.length > 0) &&
                    <>
                        <Title>{(titleSearch) ? titleSearch : intl.formatMessage({id: 'vacancy.titleGezocht'})}</Title>
                        <List
                            data={vacancies.gezocht}
                            pageSize={searchItemsPerPage || 10}
                            showImage={false}
                            categoryMainLabel={intl.formatMessage({id: 'vacancy.listCategoryMain'})}
                            categorySecondaryLabel={intl.formatMessage({id: 'vacancy.listCategorySecondary'})}
                        />
                    </>
                }
                {(vacancies.aangeboden && vacancies.aangeboden.length > 0) &&
                    <>
                        <Title>{(titleOffer) ? titleOffer : intl.formatMessage({id: 'vacancy.titleAangeboden'})}</Title>
                        <List
                            data={vacancies.aangeboden}
                            pageSize={offerItemsPerPage || 10}
                            showImage={false}
                            categoryMainLabel={intl.formatMessage({id: 'vacancy.listCategoryMain'})}
                            categorySecondaryLabel={intl.formatMessage({id: 'vacancy.listCategorySecondary'})}
                            disableSecondaryFilter
                        />
                    </>
                }
            </WrapperContent>
        </Wrapper>
    </>
);

export const VacancyOverviewTemplateIntl = injectIntl(VacancyOverviewTemplate);

const VacancyOverview = ({ data, location }) => {
    if (data && data.markdownRemark) {
        const { frontmatter, fields } = data.markdownRemark;
        const vacancies = data.allMarkdownRemark.nodes.map( (vacancy) => {
            const expertiseData = data.expertises.edges.find(expertise => expertise.node.frontmatter.id === vacancy.frontmatter.expertise);

            return {
                id: vacancy.id,
                title: vacancy.frontmatter.title,
                categoryMain: (expertiseData) ? expertiseData.node.frontmatter.title : vacancy.frontmatter.expertise,
                categorySecondary: vacancy.frontmatter.area,
                slug: vacancy.fields.slug,
                type: vacancy.frontmatter.vacancyType,
            }
        });

        const vacancyTypes = vacancies.reduce(function (r, a) {
                r[a.type] = r[a.type] || [];
                r[a.type].push(a);
                return r;
            }, Object.create(null));

        return (
            <LanguageLayout location={location}>
                <VacancyOverviewTemplateIntl
                    title={frontmatter.title}
                    description={fields.frontmattermd.pageDescriptionText.html}
                    vacancies={vacancyTypes}
                    titleOffer={frontmatter.weOfferTitle}
                    titleSearch={frontmatter.weSearchTitle}
                    offerItemsPerPage={frontmatter.vacanciesOfferItemsPerPage}
                    searchItemsPerPage={frontmatter.vacanciesSearchItemsPerPage}
                />
            </LanguageLayout>
        )
    }
    return null;
};

export default VacancyOverview;

export const pageQuery = graphql`
    query VacanciesOverviewTemplate($langKey: String!) {
        markdownRemark(frontmatter: {templateKey: {eq: "vacancies-overview"}}, fields: {langKey: {eq: $langKey}}) {
            fields {
                frontmattermd {
                    pageDescriptionText {
                        html
                    }
                }
            }
            frontmatter {
                title
                description
                weOfferTitle
                weSearchTitle
                vacanciesOfferItemsPerPage
                vacanciesSearchItemsPerPage
            }
        }
        allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC },
        filter: {frontmatter: { templateKey: { eq: "vacancy-page"}}, fields: { langKey: { eq: $langKey}}}
        ) {
            totalCount
            nodes {
                id
                frontmatter {
                    title
                    area
                    expertise
                    vacancyType
                }
                fields {
                    slug
                    langKey
                }
            }
        }
        expertises: allMarkdownRemark(
            filter:{
                frontmatter: {templateKey:{eq:"expertise-page"}}
                fields:{langKey:{eq:$langKey}}
            }){
            edges {
                node {
                    frontmatter {
                        id
                        title
                    }
                }
            }
        }
    }
`;
