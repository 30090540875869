import React from 'react';

import style from './headings.module.scss';

export const H1 = ({ className, children }) => (
    <h1 className={[style.heading1, className].join(' ')}>{children}</h1>
);

export const H2 = ({ className, children }) => (
    <h1 className={[style.heading2, className].join(' ')}>{children}</h1>
);

export const H3 = ({ className, children }) => (
    <h3 className={[style.heading3, className].join(' ')}>{children}</h3>
);

export const H4 = ({ className, children }) => (
    <h1 className={[style.heading4, className].join(' ')}>{children}</h1>
);

export const Title = ({ className, children}) => (
    <h3 className={[style.title, className].join(' ')}>{children}</h3>
);
