import React from 'react';
import PropTypes from 'prop-types';
import style from './pageintro.module.scss';

const Title = ({ children }) => (
    <h1 className={style.title}>{children}</h1>
);

const Description = ({ children, html }) => {
    if (html) {
        return (
            <div className={style.description} dangerouslySetInnerHTML={{ __html: children}} />
        )
    }
    return (
        <p className={style.description}>{children}</p>
    );
};

const PageIntro = ({ className, color, size, children, ...rest }) => (
    <div className={[
        style.pageIntro,
        (color === 'sand') ? style.sand : null,
        (color === 'thinair') ? style.thinair : null,
        (size === 'large') ? style.large : null,
        (size === 'small') ? style.small : null,
        className].join(' ')} {...rest} >
        {children}
    </div>
);

PageIntro.defaultProps = {
    size: 'large',
};
PageIntro.propsTypes = {
    color: PropTypes.oneOf(['sand', 'thinair']),
    size: PropTypes.oneOf(['small', 'large']),
    className: PropTypes.string,
};

PageIntro.title = Title;
PageIntro.description = Description;

export default PageIntro;
