import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './listItem';
import style from './list.module.scss';

const List = ({ data, page, pageSize, showImage }) => (
    <ul className={style.list}>
        {data.slice((pageSize * (page -1)), (pageSize * (page -1) + pageSize)).map((item, index) => {
            return (
                <ListItem key={`list-${index}`} {...item} showImage={showImage} />
            )
        })}
    </ul>
);

List.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        categoryMain: PropTypes.string,
        categorySecondary: PropTypes.string,
        image: PropTypes.object,
    })).isRequired,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    showImage: PropTypes.bool,
};

export default List;
